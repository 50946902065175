import React, { createContext, useCallback, useState } from 'react';

export const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [loadingCount, setLoadingCount] = useState(0);

  const setLoading = useCallback((isLoading) => {
    setLoadingCount((prevCount) => prevCount + (isLoading ? 1 : -1));
  }, []);

  const isLoading = loadingCount > 0;

  return (
    <LoadingContext.Provider value={{ isLoading, setLoading }}>{children}</LoadingContext.Provider>
  );
};
